// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/require-default-props */
import React, { FC } from 'react';
import PropTypes from 'prop-types';

interface ILogoProps {
	width?: number;
	height?: number;
}
const Logo: FC<ILogoProps> = ({ width, height }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			xmlSpace='preserve'
			id='Layer_1'
			x={0}
			y={0}
			viewBox='0 0 50 50'
			width={height !== 1500 && !!height ? height * (2155 / 1500) : width}
			height={width !== 2155 && !!width ? width * (854 / 2155) : height}>
			<style>{'.st1{fill:#c49755}'}</style>
			<g id='Final'>
				<path
					d='M27 12.3c.1-.4.4-.6.7-.8.3-.1.9-.2 1.7-.3v-.7c-1.2.1-3 .1-5.3.1h-2.4L15.5 27 8.6 10.6c-1.5.1-3.3.1-5.3.1-1.2 0-2.2 0-2.9-.1v.7c.7 0 1.3.1 1.6.3.3.1.6.4.7.8.1.4.2 1 .2 1.9v15.2c0 1.1-.1 2-.2 2.6-.1.6-.4 1-.7 1.3-.3.3-.9.5-1.6.6v.7c.7-.1 1.7-.1 2.9-.1 1.6 0 2.8 0 3.6.1V34c-1-.1-1.7-.3-2.2-.6-.4-.3-.7-.7-.9-1.3-.1-.6-.2-1.6-.2-2.9V12.8l9.1 21.7h.5l8.1-21.2v17.6c0 .9-.1 1.5-.2 1.9-.1.4-.4.6-.8.8-.4.1-1 .2-1.8.3v.7c1.1-.1 2.9-.1 5.3-.1s4.1 0 5.3.1v-.7c-.7 0-1.3-.1-1.6-.3-.3-.1-.6-.4-.7-.8-.1-.4-.2-1-.2-1.9V14.1c.2-.8.3-1.4.4-1.8zm-2 10.5v2.4c0 .4-.4.8-.8.8-.1 0-.2 0-.3-.1-.3-.1-.5-.4-.5-.7v-2.4c-.6-.3-1.1-1-1.1-1.7 0-1 .8-1.9 1.9-1.9 1 0 1.9.8 1.9 1.9 0 .7-.5 1.4-1.1 1.7z'
					style={{
						fill: '#4aab8a',
					}}
				/>
				<path
					d='M49.6 33.8v.7c-1.6-.1-3.3-.1-5.3-.1-1.5 0-2.9 0-4.3.1v-.7c.6 0 1.1-.1 1.4-.2.3-.1.4-.2.4-.5 0-.2-.1-.5-.4-.8l-4.9-7.9c-.5-.7-.8-1.2-1.1-1.4-.3-.2-.7-.4-1.2-.4v-.7c.9-.1 1.7-.3 2.4-.8s1.6-1.3 2.5-2.4l2-2.4c1-1.2 1.6-2.2 1.6-3.1 0-.5-.2-1-.7-1.3-.4-.3-1.1-.5-1.8-.5v-.6c1.6.1 3.3.1 5 .1 1.3 0 2.4 0 3.2-.1v.6c-1.8.4-3.4 1.5-4.9 3.2l-4.2 5 7.9 12.2c.3.5.7.9 1 1.2.4.3.9.5 1.4.8zM35.1 45.1c-1.7 0-3.1-1.4-3.1-3.1s1.4-3.1 3.1-3.1 3.1 1.4 3.1 3.1-1.4 3.1-3.1 3.1zm0-5.3c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2 2.2-1 2.2-2.2-1-2.2-2.2-2.2z'
					className='st1'
				/>
				<path
					d='M31.5 45.1c-1.7 0-3.1-1.4-3.1-3.1s1.4-3.1 3.1-3.1c1.7 0 3.1 1.4 3.1 3.1s-1.4 3.1-3.1 3.1zm0-5.3c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2c1.2 0 2.2-1 2.2-2.2s-.9-2.2-2.2-2.2z'
					className='st1'
				/>
				<path
					d='M33.3 47.5c-.9 0-1.6-.7-1.6-1.6s.7-1.6 1.6-1.6 1.6.7 1.6 1.6-.7 1.6-1.6 1.6zm0-2.5c-.5 0-.9.4-.9.9s.4.9.9.9.9-.4.9-.9-.4-.9-.9-.9zM33.3 39.8c-.9 0-1.6-.7-1.6-1.6s.7-1.6 1.6-1.6 1.6.7 1.6 1.6-.7 1.6-1.6 1.6zm0-2.5c-.5 0-.9.4-.9.9s.4.9.9.9.9-.4.9-.9-.4-.9-.9-.9z'
					className='st1'
				/>
				<path
					d='M32.7 33.5h1c.6 0 1.1.5 1.1 1.1V36c0 .6-.5 1.1-1.1 1.1h-1c-.6 0-1.1-.5-1.1-1.1v-1.3c0-.7.5-1.2 1.1-1.2zM32.8 10.4h1c.6 0 1.1.5 1.1 1.1v1.3c0 .6-.5 1.1-1.1 1.1h-1c-.6 0-1.1-.5-1.1-1.1v-1.3c0-.7.5-1.1 1.1-1.1z'
					className='st1'
				/>
				<path
					d='M32.8 2.5h1c.3 0 .5.2.5.5v33.5c0 .3-.2.5-.5.5h-1c-.3 0-.5-.2-.5-.5V3c0-.3.2-.5.5-.5z'
					className='st1'
				/>
				<path d='M29.9 6.8h4v2.1h-4z' className='st1' />
				<path d='M31.1 5.8h2.8v1.5h-2.8zM29.9 4h4v1.2h-4z' className='st1' />
			</g>
		</svg>
	);
};
export default Logo;
