import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import storage from 'redux-persist/lib/storage';
import {
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
	persistReducer,
	persistStore,
	Persistor,
} from 'redux-persist';

// eslint-disable-next-line import/no-cycle
import adminApi from '../services/adminApi';
// eslint-disable-next-line import/no-cycle
import { adminReducer } from './slice';

const rootReducer = combineReducers({
	admin: adminReducer,
	adminApi: adminApi.reducer,
});

const persistConfig = {
	key: 'root',
	storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,

	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(adminApi.middleware),
	devTools: true,
});

setupListeners(store.dispatch);

export const persistor: Persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
