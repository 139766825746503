import React from 'react';
import { RouteProps } from 'react-router-dom';
import { dashboardPagesMenu, demoPagesMenu } from '../menu';

import DashboardHeader from '../pages/_layout/_headers/DashboardHeader';
import UserManagementHeader from '../pages/_layout/_headers/UserManagementHeader';
import MassageCenters from '../pages/_layout/_headers/MassageCenters';
import DefaultHeader from '../pages/_layout/_headers/DefaultHeader';
import NotificationManagementHeader from '../pages/_layout/_headers/NotificationManagementHeader';
import FinanceManagementHeader from '../pages/_layout/_headers/FinanceManagementHeader';
import PastFinanceManagementHeader from '../pages/_layout/_headers/PastFinanceManagementHeader';
import PromotionManagementHeader from '../pages/_layout/_headers/PromotionManagementHeader';
import PromotionDetailHeader from '../pages/_layout/_headers/PromotionDetailHeader';
import UserDetailHeader from '../pages/_layout/_headers/UserDetailHeader';
import MassageDetailHeader from '../pages/_layout/_headers/MassageDetailHeader';
import CategoryHeader from '../pages/_layout/_headers/CategoryHeader';
import FeatureManagementHeader from '../pages/_layout/_headers/FeatureManagementHeader';
import SlotsManagementHeader from '../pages/_layout/_headers/FeaturedLimitHeader';
import SlotsDetailHeader from '../pages/_layout/_headers/SlotsDetailHeader';
import FeaturedCenterManagementHeader from '../pages/_layout/_headers/FeaturedServicesManagementHeader';
import MassageCenterHistoryHeader from '../pages/_layout/_headers/MassageCenterHistoryHeader';
import HabitKeyRequestDetailHeader from '../pages/_layout/_headers/HabitKeyRequestDetailHeader';
import FeaturedHabitKeyHeader from '../pages/_layout/_headers/FeaturedHabitKeyHeader';
import TopRatedMassageCentersHeader from '../pages/_layout/_headers/TopRatedMassageCentersHeader';
import TopRatedMassageCentersDetailHeader from '../pages/_layout/_headers/TopRatedMassageCentersDetailHeader';
import BookingHistoryDetailHeader from '../pages/_layout/_headers/BookingHistoryDetailHeader';
import MasseuseDetailHeader from '../pages/_layout/_headers/MasseuseDetailHeader';
import ServiceDetailHeader from '../pages/_layout/_headers/ServiceDetailHeader';
import RequestedFeaturedCenterManagementHeader from '../pages/_layout/_headers/RequestedFeaturedCenterManagementHeader';
import FeaturedCenterDetailHeader from '../pages/_layout/_headers/FeaturedCenterDetailHeader';
import OrderDetailHeader from '../pages/_layout/_headers/OrderDetailHeader';
import FAQHeader from "../pages/_layout/_headers/FAQ'sHeader";
import ContactQueriesHeader from '../pages/_layout/_headers/ContactQueriesHeader';
import ContactQueryDetailHeader from '../pages/_layout/_headers/ContactQueryDetailHeader';
import ProfileHeader from '../pages/_layout/_headers/ProfileHeader';
import MessageCenterConfigurationHeader from '../pages/_layout/_headers/MassageCenterConfigurationHeader';
import AddNotificationHeader from '../pages/_layout/_headers/SendNotificationHeader';
import InvoiceDetailHeader from '../pages/_layout/_headers/InvoiceDetailHeader';
import RequestedHabitMassageCenterHeader from '../pages/_layout/_headers/RequestedHabitMassageCenterHeader';

const headers: RouteProps[] = [
	{ path: demoPagesMenu.login.path, element: null },
	{ path: 'auth-pages/sign-up', element: null },
	{ path: 'auth-pages/404', element: null },
	{ path: dashboardPagesMenu.dashboard.path, element: <DashboardHeader /> },
	{
		path: dashboardPagesMenu.userManagement.path,
		element: <UserManagementHeader />,
	},
	{
		path: dashboardPagesMenu.userBookingHistoryDetail.path,
		element: <BookingHistoryDetailHeader />,
	},
	{
		path: `${dashboardPagesMenu.userDetail.path}/:id`,
		element: <UserDetailHeader />,
	},
	{
		path: dashboardPagesMenu.category.path,
		element: <CategoryHeader />,
	},
	{
		path: `${dashboardPagesMenu.massageDetail.path}/:id`,
		element: <MassageDetailHeader />,
	},
	{
		path: `${dashboardPagesMenu.masseuseDetail.path}/:id`,
		element: <MasseuseDetailHeader />,
	},
	{
		path: `${dashboardPagesMenu.serviceDetail.path}/:id`,
		element: <ServiceDetailHeader />,
	},
	{
		path: `${dashboardPagesMenu.massageCenterDetail.path}/:id`,
		element: <MassageDetailHeader />,
	},
	{
		path: dashboardPagesMenu.MassageCenters.subMenu.AllMassageCenters.path,
		element: <MassageCenters />,
	},
	{
		path: dashboardPagesMenu.MassageCenters.subMenu.TopRatedMassageCenters.path,
		element: <TopRatedMassageCentersHeader />,
	},
	{
		path: dashboardPagesMenu.MassageCenters.subMenu.TopRatedMassageCenters.path,
		element: <TopRatedMassageCentersHeader />,
	},
	{
		path: `${dashboardPagesMenu.MassageCenters.subMenu.TopRatedMassageCentersDetail.path}/:id`,
		element: <TopRatedMassageCentersDetailHeader />,
	},
	{
		path: `${dashboardPagesMenu.MassageCenters.subMenu.OrderDetail.path}/:id`,
		element: <OrderDetailHeader />,
	},
	{
		path: `${dashboardPagesMenu.MassageCenters.subMenu.InvoiceDetail.path}/:id`,
		element: <InvoiceDetailHeader />,
	},
	{
		path: dashboardPagesMenu.habitKeyManagement.subMenu.habitMassageCenterManagement.path,
		element: <FeaturedHabitKeyHeader />,
	},
	{
		path: dashboardPagesMenu.habitKeyManagement.subMenu.habitRequestedMassageCenterManagement
			.path,
		element: <RequestedHabitMassageCenterHeader />,
	},
	{
		path: `${dashboardPagesMenu.habitKeyManagement.subMenu.habitKeyRequestDetail.path}/:id`,
		element: <HabitKeyRequestDetailHeader />,
	},
	{
		path: dashboardPagesMenu.massageCenterHistory.path,
		element: <MassageCenterHistoryHeader />,
	},
	{
		path: dashboardPagesMenu.configuration.subMenu.slotManagement.path,
		element: <SlotsManagementHeader />,
	},
	{
		path: `${dashboardPagesMenu.configuration.subMenu.slotDetails.path}/:id`,
		element: <SlotsDetailHeader />,
	},
	{
		path: `${dashboardPagesMenu.configuration.subMenu.massageConfiguration.path}`,
		element: <MessageCenterConfigurationHeader />,
	},
	{
		path: `${dashboardPagesMenu.featureManagement.subMenu.featuredMassageCenterManagement.path}`,
		element: <FeaturedCenterManagementHeader />,
	},
	{
		path: `${dashboardPagesMenu.featureManagement.subMenu.featuredRequestedMassageCenterManagement.path}`,
		element: <RequestedFeaturedCenterManagementHeader />,
	},
	{
		path: `${dashboardPagesMenu.featureManagement.subMenu.featureDetail.path}/:id`,
		element: <FeaturedCenterDetailHeader />,
	},
	{
		path: dashboardPagesMenu.featureManagement.path,
		element: <FeatureManagementHeader />,
	},
	{
		path: dashboardPagesMenu.notificationManagement.path,
		element: <NotificationManagementHeader />,
	},
	{
		path: dashboardPagesMenu.sendNotification.path,
		element: <AddNotificationHeader />,
	},
	{
		path: dashboardPagesMenu.orderManagement.subMenu.upcomingOrders.path,
		element: <FinanceManagementHeader />,
	},
	{
		path: dashboardPagesMenu.orderManagement.subMenu.pastOrders.path,
		element: <PastFinanceManagementHeader />,
	},
	{
		path: dashboardPagesMenu.promotionsManagement.path,
		element: <PromotionManagementHeader />,
	},
	{
		path: `${dashboardPagesMenu.promotionDetail.path}/:id`,
		element: <PromotionDetailHeader />,
	},
	{
		path: dashboardPagesMenu.helpAndSupportManagement.subMenu.faq.path,
		element: <FAQHeader />,
	},
	{
		path: dashboardPagesMenu.helpAndSupportManagement.subMenu.contactQueries.path,
		element: <ContactQueriesHeader />,
	},
	{
		path: `${dashboardPagesMenu.contactQueryDetail.path}/:id`,
		element: <ContactQueryDetailHeader />,
	},
	{
		path: `${dashboardPagesMenu.profileManagement.path}`,
		element: <ProfileHeader />,
	},
	{
		path: `*`,
		element: <DefaultHeader />,
	},
];

export default headers;
