import { RouteProps } from 'react-router-dom';
import { demoPagesMenu } from '../menu';

const footers: RouteProps[] = [
	{ path: demoPagesMenu.login.path, element: null },
	{ path: 'auth-pages/sign-up', element: null },
	{ path: 'auth-pages/404', element: null },
];

export default footers;
