import React, { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import { dashboardPagesMenu, demoPagesMenu } from '../menu';
import Login from '../pages/presentation/auth/Login';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),
	USER_MANAGEMENT: lazy(() => import('../pages/presentation/user-management/UserManagementPage')),
	USER_DETAIL: lazy(() => import('../pages/presentation/user-management/UserDetailPage')),
	USER_BOOKING_HISTORY_DETAIL: lazy(
		() => import('../pages/presentation/user-management/BookingHistoryDetailPage'),
	),
	PLATFORM_MANAGEMENT: lazy(
		() => import('../pages/presentation/platform-management/PlatformManagementPage'),
	),
	CATEGORY: lazy(() => import('../pages/presentation/category/Category')),
	MASSAGE_CENTERS: lazy(() => import('../pages/presentation/massage-centers/MassageCenter')),
	TOP_RATED_MASSAGE_CENTERS: lazy(
		() =>
			import(
				'../pages/presentation/massage-centers/top-rated-centers/TopRatedMassageCenters'
			),
	),
	TOP_RATED_MASSAGE_CENTERS_DETAILS: lazy(
		() =>
			import(
				'../pages/presentation/massage-centers/top-rated-centers/TopRatedMassageCentersDetail'
			),
	),
	INVOICE_DETAILS: lazy(
		() => import('../pages/presentation/massage-centers/invoices-management/InvoiceDetailPage'),
	),

	ORDER_DETAILS: lazy(
		() => import('../pages/presentation/massage-centers/order-management/OrderDetail'),
	),

	HABIT_KEY_MANAGEMENT: lazy(
		() => import('../pages/presentation/habit-key-management/FeaturedHabitKeyPage'),
	),
	REQUESTED_HABIT_KEY_MANAGEMENT: lazy(
		() => import('../pages/presentation/habit-key-management/RequestedHabitKeyManagement'),
	),
	HABIT_KEY_REQUEST_DETAIL: lazy(
		() => import('../pages/presentation/habit-key-management/HabitKeyRequestDetailPage'),
	),
	MASSAGE_CENTERS_HISTORY: lazy(
		() => import('../pages/presentation/massage-centers/MassageCenterHistoryPage'),
	),

	MASSAGE_DETAIL: lazy(
		() => import('../pages/presentation/massage-centers/RequestedMassageDetailPage'),
	),
	MASSEUSE_DETAIL: lazy(
		() => import('../pages/presentation/massage-centers/masseuse-management/MasseuseDetail'),
	),
	SERVICE_DETAIL: lazy(
		() => import('../pages/presentation/massage-centers/service-management/ServiceDetail'),
	),
	MASSAGE_CENTER_DETAIL: lazy(
		() => import('../pages/presentation/massage-centers/MassageCenterDetailPage'),
	),
	REPORT_ANALYTICS: lazy(() => import('../pages/presentation/report-analytics/ReportsPage')),
	CONTENT_MANAGEMENT: lazy(
		() => import('../pages/presentation/content-management/ContentManagementPage'),
	),
	SEND_NOTIFICATION: lazy(
		() =>
			import(
				'../pages/presentation/notification-management/send-notification/SendNotificationPage'
			),
	),
	NOTIFICATION_MANAGEMENT: lazy(
		() => import('../pages/presentation/notification-management/NotificationManagementPage'),
	),
	FINANCE_MANAGEMENT: lazy(
		() => import('../pages/presentation/finance-management/FinanceManagementPage'),
	),
	PAST_FINANCE_MANAGEMENT: lazy(
		() => import('../pages/presentation/finance-management/PastFinanceManagement'),
	),
	PROMOTION_MANAGEMENT: lazy(
		() => import('../pages/presentation/promotion-management/PromotionManagementPage'),
	),
	PROMOTION_DETAIL_MANAGEMENT: lazy(
		() => import('../pages/presentation/promotion-management/PromotionDetailPage'),
	),
	PAST_REVIEW_DETAIL: lazy(
		() => import('../pages/presentation/review-management/PastReviewDetailPage'),
	),
	FAQs: lazy(() => import(`../pages/presentation/help-support/FAQ's/FAQ`)),
	CONTACT_QUERIES: lazy(
		() => import(`../pages/presentation/help-support/ContactQueries/ContactQueries`),
	),
	CONTACT_QUERIES_DETAIL: lazy(
		() => import('../pages/presentation/help-support/ContactQueries/ContactQueryDetail'),
	),
	SYSTEM_MANAGEMENT: lazy(
		() => import('../pages/presentation/system-management/SystemManagementPage'),
	),
	FEATURE_DETAIL: lazy(
		() => import('../pages/presentation/feature-management/FeatureMassageCenterDetail'),
	),
	SLOTS_MANAGEMENT: lazy(
		() =>
			import(
				'../pages/presentation/configuration/feature-limit-management/FeaturedLimitPage'
			),
	),
	SLOTS_DETAIL: lazy(
		() =>
			import(
				'../pages/presentation/configuration/feature-limit-management/FeaturedLimitDetailPage'
			),
	),
	MASSAGE_CENTER_CONFIGURATION: lazy(
		() =>
			import(
				'../pages/presentation/configuration/massageCenterConfiguration/MassageCenterConfiguration'
			),
	),
	FEATURED_MASSAGE_CENTER_MANAGEMENT: lazy(
		() => import('../pages/presentation/feature-management/FeaturedMassageCenterManagement'),
	),
	REQUESTED_FEATURED_MASSAGE_CENTER: lazy(
		() => import('../pages/presentation/feature-management/RequestedFeatureCenter'),
	),
	PROFILE_MANAGEMENT: lazy(
		() => import('../pages/presentation/profile-management/ProfileManagement'),
	),
};

const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};

const presentation: RouteProps[] = [
	/**
	 * Landing
	 */
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	{
		path: dashboardPagesMenu.userManagement.path,
		element: <LANDING.USER_MANAGEMENT isFluid />,
	},
	{
		path: dashboardPagesMenu.userBookingHistoryDetail.path,
		element: <LANDING.USER_BOOKING_HISTORY_DETAIL />,
	},
	{
		path: `${dashboardPagesMenu.userDetail.path}/:id`,
		element: <LANDING.USER_DETAIL />,
	},
	{
		path: dashboardPagesMenu.category.path,
		element: <LANDING.CATEGORY />,
	},
	{
		path: `${dashboardPagesMenu.massageDetail.path}/:id`,
		element: <LANDING.MASSAGE_DETAIL />,
	},
	{
		path: `${dashboardPagesMenu.masseuseDetail.path}/:id`,
		element: <LANDING.MASSEUSE_DETAIL />,
	},
	{
		path: `${dashboardPagesMenu.serviceDetail.path}/:id`,
		element: <LANDING.SERVICE_DETAIL />,
	},
	{
		path: `${dashboardPagesMenu.massageCenterDetail.path}/:id`,
		element: <LANDING.MASSAGE_CENTER_DETAIL />,
	},
	{
		path: dashboardPagesMenu.MassageCenters.subMenu.AllMassageCenters.path,
		element: <LANDING.MASSAGE_CENTERS />,
	},
	{
		path: dashboardPagesMenu.MassageCenters.subMenu.TopRatedMassageCenters.path,
		element: <LANDING.TOP_RATED_MASSAGE_CENTERS />,
	},
	{
		path: `${dashboardPagesMenu.MassageCenters.subMenu.TopRatedMassageCentersDetail.path}/:id`,
		element: <LANDING.TOP_RATED_MASSAGE_CENTERS_DETAILS />,
	},
	{
		path: `${dashboardPagesMenu.MassageCenters.subMenu.OrderDetail.path}/:id`,
		element: <LANDING.ORDER_DETAILS />,
	},
	{
		path: `${dashboardPagesMenu.MassageCenters.subMenu.InvoiceDetail.path}/:id`,
		element: <LANDING.INVOICE_DETAILS />,
	},
	{
		path: dashboardPagesMenu.habitKeyManagement.subMenu.habitMassageCenterManagement.path,
		element: <LANDING.HABIT_KEY_MANAGEMENT />,
	},
	{
		path: dashboardPagesMenu.habitKeyManagement.subMenu.habitRequestedMassageCenterManagement
			.path,
		element: <LANDING.REQUESTED_HABIT_KEY_MANAGEMENT />,
	},
	// {
	// 	path: dashboardPagesMenu.MassageCenters.subMenu.HabitKeyManagement.subMenu.FeaturedHabitKey
	// 		.path,
	// 	element: <LANDING.FEATURED_HABIT_KEY />,
	// },
	{
		path: `${dashboardPagesMenu.habitKeyManagement.subMenu.habitKeyRequestDetail.path}/:id`,
		element: <LANDING.HABIT_KEY_REQUEST_DETAIL />,
	},
	{
		path: dashboardPagesMenu.massageCenterHistory.path,
		element: <LANDING.MASSAGE_CENTERS_HISTORY />,
	},
	{
		path: `${dashboardPagesMenu.featureManagement.subMenu.featureDetail.path}/:id`,
		element: <LANDING.FEATURE_DETAIL />,
	},
	{
		path: dashboardPagesMenu.configuration.subMenu.slotManagement.path,
		element: <LANDING.SLOTS_MANAGEMENT />,
	},
	{
		path: `${dashboardPagesMenu.configuration.subMenu.slotDetails.path}/:id`,
		element: <LANDING.SLOTS_DETAIL />,
	},
	{
		path: `${dashboardPagesMenu.configuration.subMenu.massageConfiguration.path}`,
		element: <LANDING.MASSAGE_CENTER_CONFIGURATION />,
	},
	{
		path: `${dashboardPagesMenu.featureManagement.subMenu.featuredMassageCenterManagement.path}`,
		element: <LANDING.FEATURED_MASSAGE_CENTER_MANAGEMENT />,
	},
	{
		path: `${dashboardPagesMenu.featureManagement.subMenu.featuredRequestedMassageCenterManagement.path}`,
		element: <LANDING.REQUESTED_FEATURED_MASSAGE_CENTER />,
	},
	{
		path: dashboardPagesMenu.sendNotification.path,
		element: <LANDING.SEND_NOTIFICATION />,
	},
	{
		path: dashboardPagesMenu.notificationManagement.path,
		element: <LANDING.NOTIFICATION_MANAGEMENT />,
	},
	{
		path: dashboardPagesMenu.orderManagement.subMenu.upcomingOrders.path,
		element: <LANDING.FINANCE_MANAGEMENT />,
	},
	{
		path: dashboardPagesMenu.orderManagement.subMenu.pastOrders.path,
		element: <LANDING.PAST_FINANCE_MANAGEMENT />,
	},
	{
		path: dashboardPagesMenu.promotionsManagement.path,
		element: <LANDING.PROMOTION_MANAGEMENT />,
	},
	{
		path: `${dashboardPagesMenu.promotionDetail.path}/:id`,
		element: <LANDING.PROMOTION_DETAIL_MANAGEMENT />,
	},
	{
		path: `${dashboardPagesMenu.pastReviewDetail.path}/:id`,
		element: <LANDING.PAST_REVIEW_DETAIL />,
	},
	{
		path: dashboardPagesMenu.helpAndSupportManagement.subMenu.faq.path,
		element: <LANDING.FAQs />,
	},
	{
		path: dashboardPagesMenu.helpAndSupportManagement.subMenu.contactQueries.path,
		element: <LANDING.CONTACT_QUERIES />,
	},
	{
		path: `${dashboardPagesMenu.contactQueryDetail.path}/:id`,
		element: <LANDING.CONTACT_QUERIES_DETAIL />,
	},
	{
		path: dashboardPagesMenu.profileManagement.path,
		element: <LANDING.PROFILE_MANAGEMENT />,
	},

	/** ************************************************** */

	/**
	 * Auth Page
	 */
	{
		path: 'auth-pages/404',
		element: <AUTH.PAGE_404 />,
	},
	{
		path: demoPagesMenu.login.path,
		element: <Login />,
	},
	{
		path: 'auth-pages/sign-up',
		element: <Login />,
	},
];
const contents = [...presentation];

export default contents;
