import React, { Suspense } from 'react';
import PageWrapper from '../PageWrapper/PageWrapper';
import ContentRoutes from './ContentRoutes';
import Loader from '../../components/Loader';

// const LOADING = (
// 	<PageWrapper>
// 		<SubHeader>
// 			<div />
// 		</SubHeader>
// 		<Page>
// 			<div className='row h-100'>
// 				<div className='col-lg-6'>
// 					<Card stretch>
// 						<CardBody>
// 							<div />
// 						</CardBody>
// 					</Card>
// 				</div>
// 				<div className='col-lg-6'>
// 					<Card stretch='semi'>
// 						<CardBody>
// 							<div />
// 						</CardBody>
// 					</Card>
// 					<Card stretch='semi'>
// 						<CardBody>
// 							<div />
// 						</CardBody>
// 					</Card>
// 				</div>
// 			</div>
// 		</Page>
// 	</PageWrapper>
// );

const LOADING = (
	<PageWrapper className='align-items-center justify-content-center'>
		<Loader />
	</PageWrapper>
);

const Content = () => {
	return (
		<main className='content'>
			<Suspense fallback={LOADING}>
				<ContentRoutes />
			</Suspense>
		</main>
	);
};

export default Content;
