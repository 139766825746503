import React, { useState, KeyboardEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { message } from 'antd';

import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import { setAdminData } from '../../../redux/slice';
import { LoginResponse } from '../../../type/apiResponseTypes/apiResponseType';
import { usePostLoginMutation } from '../../../services/AuthApis/authApi';
import EmailValidation from '../../../Utils/emailValidation';
import Logo from '../../../assets/svg/LoginLogo.svg';
import Spinner from '../../../components/bootstrap/Spinner';

const LoginHeader = () => {
	return (
		<>
			<div className='text-center h1 fw-bold mt-5 mb-3'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};
LoginHeader.defaultProps = {
	isNewUser: false,
};

const Login = () => {
	const dispatch = useDispatch();
	const { darkModeStatus } = useDarkMode();
	const [postLogin] = usePostLoginMutation();
	const [isLoading, setIsLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const navigate = useNavigate();

	const loginHandler = async () => {
		if (email.trim() === '' || password.trim().length <= 0) {
			message.error(`Email or Password  can't be empty`);
			return;
		}

		if (EmailValidation(email) === false) {
			message.error(`Please Enter Valid Email`);
			return;
		}

		if (password.trim().length < 6) {
			message.error(`Password must contain 6 digit`);
			return;
		}
		setIsLoading(true);
		await postLogin({ email, password })
			.unwrap()
			.then(async (res) => {
				const { result } = res as LoginResponse;
				if (result) {
					dispatch(setAdminData(res as LoginResponse));
					message.success('Login success');
					setTimeout(() => {
						navigate('/');
						setIsLoading(false);
					}, 2000);
				} else {
					const { message: msg } = res as {
						message: string;
						result: boolean;
					};
					message.error(msg);
					setIsLoading(false);
				}
			})
			.catch((error) => {
				message.error(error.data.message);
				setIsLoading(false);
			});
	};

	const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter') {
			loginHandler();
		}
	};

	return (
		<PageWrapper title='Login' className={classNames('bg-dark')}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-1'>
									<div
										className={classNames(
											'text-decoration-none fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<img src={Logo} alt='logo' height={100} width={100} />
										<p className='h4 fw-bold'>Massage Key</p>
									</div>
								</div>
								<LoginHeader />
								<form className='row g-4'>
									<div className='col-12'>
										<Input
											autoComplete='username'
											placeholder='Enter email'
											value={email}
											onChange={(e: any) => setEmail(e.target.value)}
											className='mb-4'
										/>
										<Input
											type='password'
											autoComplete='current-password'
											placeholder='Enter password'
											value={password}
											onChange={(e: any) => setPassword(e.target.value)}
											onKeyPress={handleKeyPress}
										/>
									</div>
									<div className='col-12 mb-4'>
										<Button
											isDisable={
												email.trim() === '' ||
												password.trim().length <= 0 ||
												isLoading
											}
											color='warning'
											className='w-100 py-3'
											onClick={loginHandler}>
											{isLoading && <Spinner isSmall inButton isGrow />}
											Login
										</Button>
									</div>
								</form>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Login;
