import React from 'react';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import CommonHeaderRight from './CommonHeaderRight';

const MassageCenterConfigurationHeader = () => {
	return (
		<div className='z-0'>
			<Header>
				<HeaderLeft className='h4 mb-0 fw-bold'>Massage Center Configuration</HeaderLeft>
				<CommonHeaderRight />
			</Header>
		</div>
	);
};

export default MassageCenterConfigurationHeader;
